<template>
  <vue-final-modal v-model="show">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title ? $t(title) : $t('messageDetails') }}</h5>
          <button @click="$emit('close')" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <template v-for="(item, key) in info" :key="key">
            <div v-if="typeof item === 'object'">
              <h6 class="font-weight-bold mt-4" v-if="item.title">{{$t(item.title)}}</h6>
              <div class="mb-3" v-for="(subItem, subKey) in item.children" :key="key + subKey">
                <p class="font-weight-bold mb-0" v-if="subKey !== '_none'">{{ $t(subKey) }}</p>
                <p class="mb-0" v-if="typeof subItem !== 'object'">{{ subItem }}</p>
                <p class="mb-0" v-else><component :is="subItem"/></p>
              </div>
            </div>
            <div class="mb-3" v-else>
              <p class="font-weight-bold mb-0" v-if="key !== '_none'">{{ $t(key) }}</p>
              <p v-if="!key.includes('_html')" class="mb-0">{{ item }}</p>
              <p v-else v-html="item"></p>
            </div>
          </template>
          <template v-if="footer">
            <component :is="footer" v-bind="footerProps"></component>
          </template>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: 'TableDetailModal',
  components: {
    VueFinalModal,
  },
  data: () => ({
    show: false,
  }),
  props: {
    info: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    footer: {
      type: Object,
      required: false,
    },
    footerProps: {
      type: Object,
      required: false,
    },
  },
}
</script>
